import React, { ChangeEvent } from 'react'
import { Dispatch, connect } from 'react-redux'
import { ActionTypes } from 'redux-form'
import { IContractProviderResponse, IContractTemplateCloneRequest, IContractTemplateResponse } from '@omnicar/sam-types'
import { templateListGet } from '../../../actions/providerPageActions'
import { getContractProviders, getContractTemplates, postContractTemplateClone } from '../../../api/api'
import { contractProviderModel } from '../../../apiModels/contractProvider'
import { IContractTemplateRequestGet } from '../../../apiModels/contractTemplate'
import DataList from '../../../components/DataList/DataList'
import { IRootState } from '../../../reducers/initialState'
import PPTemplateListClone from './PPTemplateListClone'
import './PPClone.css'

const model = contractProviderModel('providerPage')

interface ICloneProps {
  onCancel: () => void
}
interface IReducerProps {
  providerId: number
}

interface IActionProps {
  templateListGet: (request: IContractTemplateRequestGet) => Promise<void>
}

interface ICloneState {
  showTemplates: boolean
  templateList: IContractTemplateResponse[]
  providerList: IContractProviderResponse[]
  cloneFromProviderId: number
  cloneFromProviderName: string
  cloneTemplateIds: number[]
  clonePrices: boolean
}

// tslint:disable-next-line interface-name Because of a lint error we need to investigate
class PPClone extends React.Component<IReducerProps & IActionProps & ICloneProps, ICloneState> {
  constructor(props: IReducerProps & IActionProps & ICloneProps) {
    super(props)
    this.state = {
      showTemplates: false,
      templateList: [],
      providerList: [],
      cloneFromProviderId: 0,
      cloneFromProviderName: '',
      cloneTemplateIds: [],
      clonePrices: false,
    }
    this.getProviders()
  }

  public render() {
    const { onCancel } = this.props
    const { showTemplates, templateList, cloneFromProviderId, cloneFromProviderName, providerList } = this.state

    return (
      <div className="PPClone">
        <header>
          <div className="PPClone__info">
            <h1 className="PPClone__title">Clone templates, options and prices</h1>
          </div>
        </header>
        <main className="PPClone__content">
          {!showTemplates ? (
            <div className="PPClone__section">
              <div className="PPClone__options">
                <h1 className="PPClone__options-title">Select provider</h1>
                <div className="PPClone__options-search">
                  <input
                    className="PPClone__options-searchfield"
                    size={40}
                    placeholder="Search Providers"
                    onChange={this.searchProviderList}
                  />
                </div>
              </div>
              <DataList
                primaryKey="contractProviderId"
                data={providerList}
                model={model}
                onRowClick={this.handleRowClick}
                activeId={cloneFromProviderId}
              />
            </div>
          ) : (
            <div className="PPClone__section">
              {cloneFromProviderName ? (
                <>
                  <div className="PPClone__templates">
                    <h1 className="PPClone__templates-title">{cloneFromProviderName}</h1>
                    <h3 className="PPClone__templates-subtitle">Select templates</h3>
                  </div>
                  <PPTemplateListClone templateList={templateList} handleClick={this.selectTemplate} />
                  <div className="PPClone__prices">
                    <input type="checkbox" onClick={this.handleClonePrices} />
                    <label className="PPClone__label">Clone prices</label>
                  </div>
                </>
              ) : (
                <h3 className="PPClone__templates-subtitle">Go back and select a provider</h3>
              )}
            </div>
          )}
        </main>
        <nav className="PPClone__actions">
          <button className="PPClone__button" onClick={onCancel}>
            Cancel
          </button>
          {!showTemplates ? (
            <button className="PPClone__button" onClick={this.handleNext}>
              Next
            </button>
          ) : (
            <span>
              <button className="PPClone__button" onClick={this.handleBack}>
                Back
              </button>
              <button className="PPClone__button" onClick={this.handleClone}>
                Clone
              </button>
            </span>
          )}
        </nav>
      </div>
    )
  }

  private getProviders = async (search?: string) => {
    const response = await getContractProviders({ search })

    if (response.data) {
      this.setState({
        providerList: response.data,
      })
    }
  }

  private searchProviderList = async (event: ChangeEvent<HTMLInputElement>) => {
    this.getProviders(event.target.value)
  }

  private handleRowClick = async (record: any) => {
    if (record && record.contractProviderId) {
      const { contractProviderId, administrativeName } = record
      const response = await getContractTemplates({ providerId: contractProviderId })
      if (response.data) {
        this.setState({
          templateList: response.data,
          cloneFromProviderId: contractProviderId,
          cloneFromProviderName: administrativeName,
          cloneTemplateIds: [],
        })
      }
    }
  }

  private selectTemplate = (e: any) => {
    const optionId = parseInt(e.currentTarget.getAttribute('value'), 10)
    let templateIds = this.state.cloneTemplateIds
    const isSelected = templateIds.find((id) => {
      return id === optionId
    })
    if (!isSelected) {
      templateIds.push(optionId)
    } else {
      templateIds = templateIds.filter((id) => id !== optionId)
    }
    this.setState({
      cloneTemplateIds: templateIds,
    })
  }

  private handleClonePrices = () => {
    this.setState({ clonePrices: !this.state.clonePrices })
  }

  private handleNext = () => {
    this.setState({
      showTemplates: true,
    })
  }

  private handleBack = () => {
    this.setState({
      showTemplates: false,
      templateList: [],
      cloneFromProviderId: 0,
      cloneFromProviderName: '',
      cloneTemplateIds: [],
      clonePrices: false,
    })
  }

  private handleClone = async () => {
    const { providerId, onCancel } = this.props
    const request: IContractTemplateCloneRequest = {
      templateIds: this.state.cloneTemplateIds,
      providerId,
      includePrices: this.state.clonePrices,
      // type: this.state.templateList.find(t => t.id ==)
    }
    const response = await postContractTemplateClone(request)
    if (response.statusCode === 204) {
      this.props.templateListGet({
        providerId,
      })
    }
    onCancel()
  }
}

const mapStateToProps = (state: IRootState) => ({
  providerId: state.providerPage.providerId,
})

const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) => ({
  templateListGet: (request: IContractTemplateRequestGet) => dispatch(templateListGet(request)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PPClone)
