import {
  IContractProviderResponse,
  IContractTemplateResponse,
  IContractOptionResponse,
  IStripeResponse,
  IUserResponse,
  ICountryResponse,
  IProductContractTemplateResponse,
  IGenericContractTemplateResponse,
  IContractProviderListItemResponse,
} from '@omnicar/sam-types'

export interface IProviderPageState {
  optionDetails?: IContractOptionResponse | null
  optionId?: number
  optionList?: IContractOptionResponse[] | null
  providerDetails?: IContractProviderResponse | null
  providerId?: number
  providerList?: IContractProviderListItemResponse[] | null
  route?: string
  stripeDetails?: IStripeResponse | null
  templateDetails?: IGenericContractTemplateResponse | null
  templateId?: number
  templateList?: IContractTemplateResponse[] | IProductContractTemplateResponse[] | null
  userList?: IUserResponse[] | null
  countryList?: ICountryResponse[] | null
  generalConditions?: string
  optionsConditions?: string
}

export const providerPageInitialState: IProviderPageState = {
  optionDetails: null,
  optionId: 0,
  optionList: null,
  providerDetails: null,
  providerId: 0,
  providerList: null,
  route: 'provider',
  stripeDetails: null,
  templateDetails: null,
  templateId: 0,
  templateList: null,
  userList: null,
  countryList: null,
  generalConditions: '',
  optionsConditions: '',
}
