import { IContractOptionResponse } from '@omnicar/sam-types'
import { priceSpecificationValueDefaults, priceSpecificationModel } from './priceSpecification'
import IApiModel, { IApiField, createModel } from './apiModel'
import {
  requiredPrimaryKey,
  stringMaxLength,
  requiredString,
  requiredNumber,
  numberIsWhole,
  requiredBoolean,
} from './validations'

export interface IContractOptionRequestPatch extends IContractOptionResponse {
  archived?: boolean
}

export interface IContractOptionRequestPost extends IContractOptionResponse {}

export interface IContractOptionRequestGet {
  id?: number
  contractProviderId?: number
}

export interface IContractConditionsRequestGet {
  contractProviderId: number
}

// Default Values
export const contractOptionValueDefaults: IContractOptionResponse = {
  id: -1,
  contractProviderId: null,
  conditionText: '',
  description: '',
  price: priceSpecificationValueDefaults,
  weight: 0,
  abbreviation: '',
  optionGroup: 0,
  internalName: '',
  showOnWebcalc: true,
  warranty: null,
  calculationMethod: 200,
}

// API Model
const model: IApiModel = {
  name: 'contractOptionModel',
  fields: [
    {
      name: 'id',
      title: 'Option ID',
      uiType: 'string',
      validation: [requiredPrimaryKey],
    },
    {
      name: 'contractProviderId',
      title: 'Contract Provider',
      uiType: 'number',
      validation: [requiredNumber],
    },
    {
      name: 'conditionText',
      title: 'Condition Text',
      uiType: 'string',
    },
    {
      name: 'internalName',
      uiType: 'string',
      title: 'Internal Name (used in pricefile)',
    },
    {
      name: 'description',
      uiType: 'string',
      title: 'External Name (shown on website)',
      validation: [requiredString],
    },
    {
      name: 'weight',
      uiType: 'Number',
      title: 'Weight',
    },
    {
      name: 'price',
      uiType: 'string',
      title: 'Price Specifications',
      relation: 'PriceSpecification',
    },
    {
      name: 'abbreviation',
      uiType: 'string',
      title: 'Abbreviation',
      validation: [stringMaxLength(3)],
    },
    {
      name: 'sumTemplates',
      uiType: 'string',
      title: 'Option used in # of Templates',
      validation: [requiredString],
    },
    {
      name: 'templateNames',
      uiType: 'string',
      title: 'Templates',
      validation: [requiredString],
    },
    {
      name: 'optionGroup',
      uiType: 'string',
      title: 'Option group',
      validation: [numberIsWhole],
    },
    {
      name: 'showOnWebcalc',
      uiType: 'boolean',
      title: 'Available on Webcalc',
      validation: [requiredBoolean],
    },
    {
      name: 'calculationMethod',
      uiType: 'string',
      title: 'Calculation Method',
    },
    {
      name: 'warranty',
      uiType: 'string',
      title: 'Warranty Type',
    },
  ],
  view: {
    providerPage: [
      {
        name: 'id',
      },
      {
        name: 'internalName',
      },
      {
        name: 'description',
      },
      {
        name: 'sumTemplates',
      },
      {
        name: 'templateNames',
        uiType: 'csv',
      },
    ],
  },
}

const priceSpecificationRelation = priceSpecificationModel()

export const contractOptionModel = (view?: string | IApiField[], viewContext?: string) =>
  createModel<IContractOptionResponse>(view, viewContext, model, contractOptionValueDefaults, {
    PriceSpecification: { name: 'PriceSpecification', model: priceSpecificationRelation },
  })
