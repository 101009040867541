import React, { ChangeEvent, KeyboardEvent } from 'react'
import withStyledField, { IWithStyledFieldProps } from '../../../hoc/withStyledField/withStyledField'
import './StringField.css'

interface IProps extends IWithStyledFieldProps {
  className: string
  disabled?: boolean
  maxLength?: number
  name: string
  placeholder?: string
  onBlur?: (e: any) => void
  onChange?: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  size?: number
  value?: string
  asTextarea?: boolean
}

const StringField: React.SFC<IProps> = ({
  className,
  disabled,
  hidden,
  maxLength,
  name,
  placeholder,
  onBlur,
  onChange,
  onKeyDown,
  size,
  value,
  asTextarea,
}) =>
  asTextarea ? (
    <textarea
      className={'conditions_input'}
      disabled={disabled}
      hidden={hidden}
      id={name}
      maxLength={maxLength}
      name={name}
      placeholder={placeholder}
      onBlur={onBlur}
      onChange={onChange}
      onKeyDown={onKeyDown}
      value={value}
      cols={50}
      rows={10}
    />
  ) : (
    <input
      type="text"
      className={className}
      disabled={disabled}
      hidden={hidden}
      id={name}
      maxLength={maxLength}
      name={name}
      placeholder={placeholder}
      onBlur={onBlur}
      onChange={onChange} // This continues to work for input
      onKeyDown={onKeyDown} // Replaced onKeyPress with onKeyDown
      size={size}
      value={value}
    />
  )

StringField.defaultProps = {
  className: '',
  disabled: false,
  hidden: false,
  value: '',
}

export default withStyledField(StringField, 'StringField')
