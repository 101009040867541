import * as React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
// Removing this for now to check out how this will work
// without it - and also because "homePagePath" as a variable
// is not readable for me - what is "homepagepath"??
// import { homePagePath } from './paths'

// Implement GA into react projects as read here:
// https://github.com/react-ga/react-ga/wiki/React-Router-v4-withTracker
import withTracker from '../hoc/withTracker/WithTracker'

// Import the pages we want to use in our project
import ProviderPage from '../pages/ProviderPage/ProviderPage'
import Error404Page from '../pages/Error404Page/Error404Page'
import ModelsPage from '../pages/ModelsPage/ModelsPage'
import {
  loginPagePath,
  homePagePath,
  providerPagePath,
  modelsPagePath,
  releaseVersionsPagePath,
  releaseVersionsViewPagePath,
  translationPagePath,
  accessKeyPagePath,
} from '../routes/paths'
import ReleaseVersionsEditPage from '../pages/ReleaseNotesPage/ReleaseVersions/edit/ReleaseVersionsPage'
import ReleaseVersionsViewPage from '../pages/ReleaseNotesPage/ReleaseVersions/view/ReleaseVersionsPage'
import TranslationPage from '../pages/TranslationPage'
import AccessKeyPage from '../pages/AccessKeyPage'
import { selectIsUserDev } from '../selectors/user'
import { IRootState } from 'reducers/initialState'
import { connect } from 'react-redux'

interface IProps {
  isUserDev: boolean
}

const Routes: React.SFC<IProps> = () => (
  <Switch>
    <Route exact={true} path={homePagePath} component={withTracker(ProviderPage)} />
    <Route exact={false} path={providerPagePath} component={withTracker(ProviderPage)} />
    <Route exact={false} path={modelsPagePath} component={withTracker(ModelsPage)} />
    <Route exact={true} path={releaseVersionsViewPagePath} component={withTracker(ReleaseVersionsViewPage)} />
    <Route exact={true} path={translationPagePath} component={withTracker(TranslationPage)} />
    <Route exact={true} path={releaseVersionsPagePath} component={withTracker(ReleaseVersionsEditPage)} />
    <Route exact={true} path={accessKeyPagePath} component={withTracker(AccessKeyPage)} />
    <Route path={loginPagePath} render={redirectToHomePage} />
    <Route path={'*'} component={withTracker(Error404Page)} />
  </Switch>
)

const mapStateToProps = (state: IRootState) => ({
  isUserDev: selectIsUserDev(state),
})

export default connect(mapStateToProps)(Routes)
const redirectToHomePage = () => <Redirect to={homePagePath} />
