import React from 'react'
import { ITranslationResponse, ITranslationApplication } from '@omnicar/sam-types'
import FormSection from 'components/FormSection/FormSection'
import ApplicationSelector from './ApplicationSelector'
import StringField from 'components/Field/StringField/StringField'
import { isEqual } from 'lodash'
import { patchTranslationApplications, createTranslationKey, searchTranslationKeys } from 'api/api'
import TranslationValuesList from '../Lists/TranslationValuesList'
import { showSuccess, showWarning } from 'utils/toastify'

interface IProps {
  translation?: ITranslationResponse
  applications: ITranslationApplication[]
  onReset: () => void
  updateTranslationsList: (translation: ITranslationResponse) => void
}

interface IState {
  translationKey: string | undefined
  currentApplications: ITranslationApplication[]
  translationComment: string | undefined
  keyExist: boolean
}

class TranslationForm extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      translationKey: props.translation?.key,
      translationComment: props.translation?.comment,
      currentApplications: props.translation?.applications || [],
      keyExist: false,
    }
  }

  public componentDidUpdate(prevProps: IProps) {
    const { translation } = this.props

    if (prevProps.translation?.key !== translation?.key) {
      this.setState({
        translationKey: translation?.key,
        translationComment: translation?.comment,
        currentApplications: translation?.applications || [],
      })
    }
  }

  render() {
    const { applications, translation, onReset } = this.props
    const { currentApplications, translationKey, translationComment, keyExist } = this.state

    return (
      <div className={'Translations__form'}>
        <FormSection name="general" classNamePrefix="Translation">
          <ApplicationSelector
            applications={applications}
            currentApplications={currentApplications}
            onChange={this.handleApplications}
          />
          <span title="Translation key">
            <StringField
              className="Translation__key"
              name="key"
              onChange={this.handleKeyChange}
              onBlur={this.handleKeyBlur}
              size={72}
              title="Translation key"
              value={translationKey}
              disabled={!!translation}
              errors={keyExist ? ['Duplicated key'] : undefined}
            />
          </span>
          <span title="Translation comment">
            <StringField
              className="Translation__comment"
              name="comment"
              onChange={this.handleCommentChange}
              size={72}
              title="Translation comment"
              value={translationComment}
            />
          </span>
        </FormSection>
        <button className="Translations__resetbutton" onClick={onReset}>
          Cancel
        </button>
        <button className="Translations__submitbutton" disabled={this.disableSubmit()} onClick={this.handleSubmit}>
          {`${translation ? 'Update' : 'Create'} key`}
        </button>
        <TranslationValuesList translation={translation} />
      </div>
    )
  }

  disableSubmit = () => !(this.validateForm() && this.formDirty() && !this.state.keyExist)

  private handleKeyBlur = async () => {
    const { translationKey } = this.state
    let keyExist = false

    if (translationKey) {
      const { statusCode, data } = await searchTranslationKeys(translationKey)
      const result = (statusCode === 200 && data) || undefined

      keyExist = !!result && result?.some((t) => t.key === translationKey)
    }

    this.setState({ keyExist })
  }

  private validateForm = (): boolean => !!(this.state.translationKey && this.state.currentApplications.length)

  private formDirty = (): boolean => {
    const { translation } = this.props
    const { currentApplications, translationKey, translationComment } = this.state
    const sort = (a: ITranslationApplication, b: ITranslationApplication) => a.id - b.id

    return translation
      ? !(
          isEqual(currentApplications.sort(sort), translation.applications.sort(sort)) &&
          translation.comment === translationComment
        )
      : currentApplications.length > 0 && !!translationKey
  }

  handleApplications = (e: React.ChangeEvent<HTMLInputElement>) => {
    const appName = e.target.id
    const { applications } = this.props

    this.setState(({ currentApplications }) => ({
      currentApplications: currentApplications.some((a) => a.name === appName)
        ? currentApplications.filter((a) => a.name !== appName)
        : [...currentApplications, applications.find((a) => a.name === appName)],
    }))
  }

  private handleKeyChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    this.setState({ translationKey: e.target.value })

  private handleCommentChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    this.setState({ translationComment: e.target.value ? e.target.value : undefined })

  handleSubmit = async () => {
    const { translation: currentTranslation, updateTranslationsList } = this.props
    const { translationKey, currentApplications, translationComment } = this.state
    const { statusCode, data } = currentTranslation
      ? await patchTranslationApplications(currentTranslation.id, {
          ...currentTranslation,
          applications: currentApplications,
          comment: translationComment,
        })
      : await createTranslationKey({
          key: translationKey!,
          applications: currentApplications,
          comment: translationComment,
        })
    const translation = (statusCode === 200 && data) || undefined

    if (translation) {
      showSuccess(currentTranslation ? 'Updated' : 'Created')
      updateTranslationsList(translation)
    } else {
      statusCode === 400 && showWarning('Probable duplicated key')
    }
  }
}

export default TranslationForm
