import { IContractOptionResponse, IContractProviderResponse, IContractTemplateResponse } from '@omnicar/sam-types'
import React, { MouseEvent } from 'react'
import { connect, Dispatch } from 'react-redux'
import ActionTypes from '../../../actions/ActionTypes'
import {
  IProviderPageProviderDetailsReset,
  IProviderPageRouteUpdate,
  providerDetailsReset,
  providerPageRouteUpdate,
} from '../../../actions/providerPageActions'
import { IRootState } from '../../../reducers/initialState'
import './PPNavigation.css'

// import browserHistory from '../../../utils/history'

interface IReducerProps {
  optionDetails: IContractOptionResponse
  optionId: number
  providerDetails: IContractProviderResponse
  providerId: number
  route: string
  templateDetails: IContractTemplateResponse
  templateId: number
}

interface IActionProps {
  providerPageRouteUpdate: (route: string) => IProviderPageRouteUpdate
  providerDetailsReset: () => IProviderPageProviderDetailsReset
}

// tslint:disable-next-line interface-name Because of a lint error we need to investigate
class PPNavigation extends React.Component<IReducerProps & IActionProps, {}> {
  public render() {
    const { route, providerPageRouteUpdate } = this.props
    const breadcrumbSubRoot = this.getBreadcrumbSubRoot()
    const breadcrumbPath = this.getBreadcrumbPath()
    const breadcrumbCreatePath = this.getBreadcrumbCreatePath()

    const isUsingV4PricingTool = this.props.providerDetails?.isUsingV4PricingTool
    const isChildProvider = this.props.providerDetails?.parentProviderId! > 0

    return (
      <div className="PPNavigation">
        {route !== 'provider-create' && (
          <div className="PPNavigation__menu">
            <div>
              <button
                className={`PPNavigation__menu-item PPNavigation__menu-item-details ${
                  route === 'provider-details' ? 'PPNavigation__menu-item--active' : ''
                }`}
                // tslint:disable-next-line:jsx-no-lambda
                onClick={() => providerPageRouteUpdate('provider-details')}
              >
                Details
              </button>
              {!isUsingV4PricingTool && (
                <button
                  className={`PPNavigation__menu-item PPNavigation__menu-item-template ${
                    route === 'template' || route === 'template-details' || route === 'template-create'
                      ? 'PPNavigation__menu-item--active'
                      : ''
                  }`}
                  // tslint:disable-next-line:jsx-no-lambda
                  onClick={() => providerPageRouteUpdate('template')}
                >
                  Templates
                </button>
              )}
              {!isUsingV4PricingTool && (
                <button
                  className={`PPNavigation__menu-item PPNavigation__menu-item-option ${
                    route === 'option' || route === 'option-details' || route === 'option-create'
                      ? 'PPNavigation__menu-item--active'
                      : ''
                  }`}
                  // tslint:disable-next-line:jsx-no-lambda
                  onClick={() => providerPageRouteUpdate('option')}
                >
                  Options
                </button>
              )}
              {!isChildProvider && (
                <button
                  className={`PPNavigation__menu-item PPNavigation__menu-item-stripe ${
                    route === 'stripe' || route === 'external-account-create' ? 'PPNavigation__menu-item--active' : ''
                  }`}
                  // tslint:disable-next-line:jsx-no-lambda
                  onClick={() => providerPageRouteUpdate('stripe')}
                >
                  Stripe
                </button>
              )}
              <button
                className={`PPNavigation__menu-item PPNavigation__menu-item-user ${
                  route === 'user' ? 'PPNavigation__menu-item--active' : ''
                }`}
                // tslint:disable-next-line:jsx-no-lambda
                onClick={() => providerPageRouteUpdate('user')}
              >
                Users
              </button>
              {!isUsingV4PricingTool && (
                <button
                  className={`PPNavigation__menu-item PPNavigation__menu-item-prices ${
                    route === 'prices' ? 'PPNavigation__menu-item--active' : ''
                  }`}
                  // tslint:disable-next-line:jsx-no-lambda
                  onClick={() => providerPageRouteUpdate('prices')}
                >
                  Prices &amp; Warranties
                </button>
              )}
            </div>
            {/* 
            // Below doesn't work for some reason!
            <div onClick={browserHistory.goBack}>Go Back</div> */}
          </div>
        )}
        <div className="PPNavigation__breadcrumb">
          <span className="PPNavigation__breadcrumb-item" data-route="provider" onClick={this.handleBreadcrumbClick}>
            Providers
          </span>
          {route !== 'provider-create' && (
            <span
              className="PPNavigation__breadcrumb-item"
              data-route="provider-details"
              onClick={this.handleBreadcrumbClick}
            >
              Provider
            </span>
          )}
          {breadcrumbSubRoot.value && (
            <span
              className="PPNavigation__breadcrumb-item"
              data-route={breadcrumbSubRoot.value}
              onClick={this.handleBreadcrumbClick}
            >
              {breadcrumbSubRoot.title}
            </span>
          )}
          {breadcrumbCreatePath.value && (
            <span
              className="PPNavigation__breadcrumb-item"
              data-route={breadcrumbCreatePath.value}
              onClick={this.handleBreadcrumbClick}
            >
              {breadcrumbCreatePath.title}
            </span>
          )}
          {breadcrumbPath.value && (
            <span
              className="PPNavigation__breadcrumb-item"
              data-route={breadcrumbPath.value}
              onClick={this.handleBreadcrumbClick}
            >
              {breadcrumbPath.title}
            </span>
          )}
        </div>
      </div>
    )
  }

  private getBreadcrumbPath = () => {
    const { route } = this.props
    const breadcrumbPath = {
      title: '',
      value: '',
    }

    switch (route) {
      case 'provider-create':
        breadcrumbPath.title = 'New Provider'
        breadcrumbPath.value = 'provider-create'
        break
      case 'template-details':
        const { templateDetails } = this.props

        if (templateDetails) {
          breadcrumbPath.title = templateDetails.name
          breadcrumbPath.value = 'template-details'
        }
        break
      case 'template-create':
        breadcrumbPath.title = 'New Template'
        breadcrumbPath.value = 'template-create'
        break
      case 'product-template-create':
        breadcrumbPath.title = 'New Template for Products'
        breadcrumbPath.value = 'product-template-create'
        break
      case 'option-details':
        const { optionDetails } = this.props

        if (optionDetails) {
          breadcrumbPath.title = optionDetails.description
          breadcrumbPath.value = 'option-details'
        }
        break
      case 'option-create':
        breadcrumbPath.title = 'New Option'
        breadcrumbPath.value = 'option-create'
        break
      case 'external-account-create':
        breadcrumbPath.title = 'New External Account'
        breadcrumbPath.value = 'external-account-create'
        break
      default:
        break
    }

    return breadcrumbPath
  }

  private getBreadcrumbCreatePath = () => {
    const { route } = this.props
    const breadcrumbCreatePath = {
      title: '',
      value: '',
    }

    switch (route) {
      case 'template-create':
        breadcrumbCreatePath.title = 'Templates'
        breadcrumbCreatePath.value = 'template'
        break
      case 'option-create':
        breadcrumbCreatePath.title = 'Options'
        breadcrumbCreatePath.value = 'option'
        break
      case 'external-account-create':
        breadcrumbCreatePath.title = 'Stripe'
        breadcrumbCreatePath.value = 'stripe'
        break
      default:
        break
    }

    return breadcrumbCreatePath
  }

  private getBreadcrumbSubRoot = () => {
    const { route } = this.props
    const breadcrumbSubRoot = {
      title: '',
      value: '',
    }

    switch (route) {
      case 'template':
      case 'template-details':
        breadcrumbSubRoot.title = 'Templates'
        breadcrumbSubRoot.value = 'template'
        break
      case 'option':
      case 'option-details':
        breadcrumbSubRoot.title = 'Options'
        breadcrumbSubRoot.value = 'option'
        break
      case 'stripe':
        breadcrumbSubRoot.title = 'Stripe'
        breadcrumbSubRoot.value = 'stripe'
        break
      case 'user':
        breadcrumbSubRoot.title = 'Users'
        breadcrumbSubRoot.value = 'Users'
        break
      case 'prices':
        breadcrumbSubRoot.title = 'Prices'
        breadcrumbSubRoot.value = 'Prices'
        break

      default:
        break
    }

    return breadcrumbSubRoot
  }

  private handleBreadcrumbClick = (event: MouseEvent<HTMLSpanElement>) => {
    const { route } = event.currentTarget.dataset

    if (route === 'provider') {
      this.props.providerDetailsReset()
    }

    if (route) {
      this.props.providerPageRouteUpdate(route)
    }
  }
}

const mapStateToProps = (state: IRootState) => ({
  optionDetails: state.providerPage.optionDetails,
  optionId: state.providerPage.optionId,
  providerDetails: state.providerPage.providerDetails,
  providerId: state.providerPage.providerId,
  route: state.providerPage.route,
  templateDetails: state.providerPage.templateDetails,
  templateId: state.providerPage.templateId,
})

const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) => ({
  providerPageRouteUpdate: (route: string) => dispatch(providerPageRouteUpdate(route)),
  providerDetailsReset: () => dispatch(providerDetailsReset()),
})

export default connect(mapStateToProps, mapDispatchToProps)(PPNavigation)
