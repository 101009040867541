import React from 'react'
import { Link } from 'react-router-dom'
import './AppNavigation.css'
import { Dispatch, connect } from 'react-redux'
import ActionTypes from '../../actions/ActionTypes'
import {
  providerPagePath,
  modelsPagePath,
  releaseVersionsPagePath,
  releaseVersionsViewPagePath,
  translationPagePath,
  accessKeyPagePath,
} from '../../routes/paths'
import { storeReset, IStoreReset } from '../../actions/storeActions'
import { selectRootPage } from '../../selectors/router'
import { IRootState } from '../../reducers/initialState'
import cn from 'classnames'
import logoFragus from '../../images/fragus-logo-wh.png'
import logoJustGO from '../../images/JustGO-logo-tr.png'
import { selectIsUserDev } from '../../selectors/user'
import { setLocalStoreItem } from 'utils/localStorage'
import { clearTokens } from 'utils/auth'
import { logout } from 'api/api'

const navigation = [
  {
    name: 'provider',
    title: 'Providers',
    to: providerPagePath,
  },
  {
    name: 'models',
    title: 'Models',
    to: modelsPagePath,
  },
  {
    name: 'translations-page',
    title: 'Edit Translations',
    to: translationPagePath,
  },
  {
    name: 'releases',
    title: 'Edit Release Notes',
    to: releaseVersionsPagePath,
    devOnly: true,
  },
  {
    name: 'access-keys',
    title: 'Edit Access Keys',
    to: accessKeyPagePath,
    devOnly: true,
  },
  {
    name: 'release-notes-page',
    title: 'Release Notes',
    to: releaseVersionsViewPagePath,
  },
]

interface IProps {
  collapsed: boolean
  rootPage: string
  storeReset: () => IStoreReset
  isUserDev: boolean
}

class AppNavigation extends React.Component<IProps, {}> {
  public static defaultProps: Partial<IProps> = {
    collapsed: false,
  }

  public render() {
    const { collapsed, rootPage } = this.props

    // Remove the filter, so all items are visible to everyone
    const filteredNavigation = navigation

    return (
      <div className="AppNavigation">
        <div onClick={() => setLocalStoreItem('wasRedirect', 'true')}>
          <img
            src={logoFragus}
            className={cn('AppNavigation__logo', collapsed && 'AppNavigation__logo--collapsed')}
            alt="Fragus"
          />
          <a href="/">
            <img
              src={logoJustGO}
              className={cn('AppNavigation__JustGO', collapsed && 'AppNavigation__JustGO--collapsed')}
              alt="JustGO"
            />
          </a>
          <div className={cn('AppNavigation__title', collapsed && 'AppNavigation__title--collapsed')}>
            <div className="AppNavigation__title-name">Fragus JustGO/SAM Superadmin</div>
          </div>
        </div>
        {filteredNavigation.map(({ name, title, to }) => {
          const isSelected = rootPage === name || (name === 'provider' && rootPage === '')

          return (
            <Link
              className={cn(
                'AppNavigation__item',
                isSelected && 'AppNavigation__item--selected',
                collapsed && 'AppNavigation__item--collapsed',
                `AppNavigation__item--${name}`,
              )}
              to={to}
              key={name}
            >
              <span
                className={cn(
                  'AppNavigation__item-icon',
                  collapsed && 'AppNavigation__item-icon--collapsed',
                  isSelected && `AppNavigation__item-icon--${name}-selected`,
                  `AppNavigation__item-icon--${name}`,
                )}
              />
              <span
                className={cn(
                  'AppNavigation__item-text',
                  collapsed && 'AppNavigation__item-text--collapsed',
                  isSelected && 'AppNavigation__item-text--selected',
                )}
              >
                {title}
              </span>
            </Link>
          )
        })}
        <div
          className={cn(
            'AppNavigation__item',
            'AppNavigation__item--logout',
            collapsed && 'AppNavigation__item--collapsed',
          )}
          onClick={this.handleLogOut}
        >
          <span
            className={cn(
              'AppNavigation__item-icon',
              collapsed && 'AppNavigation__item-icon--collapsed',
              'AppNavigation__item-icon--logout',
            )}
          />
          <span className={cn('AppNavigation__item-text', collapsed && 'AppNavigation__item-text--collapsed')}>
            {'log out'}
          </span>
        </div>
      </div>
    )
  }

  private handleLogOut = async () => {
    await logout()
    clearTokens()
  }
}

const mapStateToProps = (state: IRootState) => ({
  rootPage: selectRootPage(state),
  isUserDev: selectIsUserDev(state),
})

const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) => ({
  storeReset: () => dispatch(storeReset()),
})

export default connect(mapStateToProps, mapDispatchToProps)(AppNavigation)
