import React, { ChangeEvent } from 'react'
import { IApiErrors } from '../../../apiModels/apiModel'
import { IAddressResponse } from '@omnicar/sam-types'
import FormSection from '../../FormSection/FormSection'
import Country from '../Country/Country'
import StringField from '../../Field/StringField/StringField'
import { getCityFromZip } from '../../../utils/getCityFromZip'
import { Field, FieldProps } from 'formik'
import { BaseOption } from 'types'

interface IProps {
  edit?: boolean
  errors?: IApiErrors
  name?: string
  onBlur: (e: ChangeEvent<any>) => void
  onChange: (e: ChangeEvent<any>) => void
  title?: boolean | string
  value: IAddressResponse
  countryOptions: BaseOption[]
  countryReadonly?: boolean
}

// tslint:disable jsx-no-lambda
const Address: React.SFC<IProps> = ({
  edit,
  errors = {},
  name,
  title,
  value,
  onBlur,
  onChange,
  countryOptions,
  countryReadonly,
}) => (
  <div className="Address">
    <FormSection classNamePrefix="Address" name="general" title={title ? (title === true ? 'Address' : title) : ''}>
      <StringField
        className="Address__address1"
        disabled={!edit}
        errors={errors.address1}
        locked={!edit}
        name={name ? `${name}.address1` : 'address1'}
        onBlur={onBlur}
        onChange={onChange}
        size={55}
        title="Address 1"
        value={value.address1}
      />
      <StringField
        className="Address__address2"
        disabled={!edit}
        errors={errors.address2}
        locked={!edit}
        name={name ? `${name}.address2` : 'address2'}
        onBlur={onBlur}
        onChange={onChange}
        size={55}
        title="Address 2"
        value={value.address2}
      />
      <Field name={name ? `${name}.postalCode` : 'postalCode'}>
        {({ form }: FieldProps) => (
          <StringField
            className="Address__postalCode"
            disabled={!edit}
            errors={errors.postalCode}
            locked={!edit}
            name={name ? `${name}.postalCode` : 'postalCode'}
            onBlur={onBlur}
            onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
              onChange(e)

              const country = value.country.isoName.toLowerCase()
              const zipCode = getCityFromZip(e.target.value, country)

              if (zipCode) {
                form.setFieldValue(name ? `${name}.city` : 'city', zipCode)
              }
            }}
            size={12}
            title="Postal Code"
            value={value.postalCode}
          />
        )}
      </Field>
      <StringField
        className="Address__city"
        disabled={!edit}
        errors={errors.city}
        locked={!edit}
        name={name ? `${name}.city` : 'city'}
        onBlur={onBlur}
        onChange={onChange}
        size={35}
        title="Postal City"
        value={value.city}
      />
      <Country
        edit={edit && !countryReadonly}
        options={countryOptions}
        errors={errors.country}
        name={name ? `${name}.country` : 'country'}
        onBlur={onBlur}
        onChange={onChange}
        value={value.country}
      />
    </FormSection>
  </div>
)
// tslint:enable jsx-no-lambda

export default Address
