import React, { ChangeEvent } from 'react'
import TabPanel from 'components/TabPanel/TabPanel'
import { IsoLocale, IReleaseDescription } from '@omnicar/sam-types'
import './ReleaseDescription.css'
import StringField from 'components/Field/StringField/StringField'

const localesList: IsoLocale[] = ['en-GB', 'da-DK', 'sv-SE', 'fi-FI', 'nb-NO']

interface IProps {
  values: IReleaseDescription[]
  onChange: (descriptions: IReleaseDescription[]) => void
}

interface IState {
  tabIndex: number
}

class ReleaseDescription extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      tabIndex: 0,
    }
  }

  render() {
    return (
      <span title="Release Description" className="ReleaseDescription">
        <span className="ReleaseDescription__label">Description</span>
        <TabPanel
          tabIndex={this.state.tabIndex}
          onChange={this.handleTabChange}
          tabTitles={localesList}
          tabComponents={localesList.map((locale) => (
            <StringField
              className="ReleaseDescription__description"
              name="description"
              onBlur={this.createChangeHandler(locale)}
              onChange={this.createChangeHandler(locale)}
              size={55}
              title="Description"
              value={this.getValueByLocale(locale)}
            />
          ))}
        />
      </span>
    )
  }

  private getValueByLocale = (locale: IsoLocale): string | undefined =>
    this.props.values.find((d) => d.locale === locale)?.description

  private createChangeHandler = (
    locale: IsoLocale,
  ): ((e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void) => {
    const { values, onChange } = this.props
    const hasLocale = values.some((d) => d.locale === locale)
    return (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value } = e.target
      const newDescriptions = values.map((d) => {
        return d.locale === locale
          ? {
              ...d,
              description: value,
            }
          : d
      })

      !hasLocale &&
        newDescriptions.push({
          locale,
          description: value,
        })

      onChange(newDescriptions)
    }
  }

  private handleTabChange = (_e: React.ChangeEvent<{}>, tabIndex: number) => this.setState({ tabIndex })
}
export default ReleaseDescription
